import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RiShareCircleFill } from "react-icons/ri";
import urljoin from "url-join";
import DIMENSIONS from "../../helpers/DIMENSIONS";
import useWindowDimensions from "../../helpers/GetWindowDimensions";
import * as S from "../../styles/styles.css";
import SocialLinks from "../SocialLinks/SocialLinks";
import UserLinks from "../UserLinks/UserLinks";

function Footer({ config, postPath, postNode, basicView }) {
  const { width: windowWidth } = useWindowDimensions();
  const post = !basicView && postNode.frontmatter;
  const excerpt = !basicView && postNode.excerpt;
  const [showShareMenu, setShowShareMenu] = useState(false);
  const url =
    !basicView && urljoin(config.siteUrl, config.pathPrefix, postPath);
  // const url = config.siteRss;
  const { copyright } = config;
  if (!copyright) {
    return null;
  }
  return (
    <S.FooterSeb>
      <S.FooterShareMenuDiv visible={showShareMenu}>
        {postNode && <SocialLinks post={post} url={url} excerpt={excerpt} />}
      </S.FooterShareMenuDiv>
      <Row>
        {postNode && (
          <Col xs={1} sm={3}>
            <Row>
              <Col>
                {postNode && windowWidth < DIMENSIONS.breakpoints.mobile && (
                  <h5 style={{ marginTop: "0.3em", marginLeft: "0.5em" }}>
                    <RiShareCircleFill
                      color="white"
                      onClick={() =>
                        !showShareMenu
                          ? setShowShareMenu(true)
                          : setShowShareMenu(false)
                      }
                    />
                  </h5>
                )}
              </Col>
              <Col>
                {postNode && windowWidth > DIMENSIONS.breakpoints.mobile && (
                  <SocialLinks post={post} url={url} excerpt={excerpt} />
                )}
              </Col>
            </Row>
          </Col>
        )}
        <Col>
          <div
            style={{
              height: "100%",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <S.CopyrightPanel
              className={!postNode && "mx-5"}
              style={{
                left:
                  postNode &&
                  windowWidth < DIMENSIONS.breakpoints.mobile &&
                  "50px",
              }}
            >
              {windowWidth > DIMENSIONS.breakpoints.mobile
                ? `${copyright}`
                : "© 2022 Sebastian Grant"}
            </S.CopyrightPanel>
          </div>
        </Col>

        <Col>
          <UserLinks config={config} labeled />
        </Col>
      </Row>
    </S.FooterSeb>
  );
}

export default Footer;
