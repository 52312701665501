import React from "react";
import { FaEnvelope, FaFacebook } from "react-icons/fa";
import * as S from "../../styles/styles.css";
import "./UserLinks.css";
import useWindowDimensions from "../../helpers/GetWindowDimensions";
import DIMENSIONS from "../../helpers/DIMENSIONS";

function UserLinks({ config }) {
  const { width: windowWidth } = useWindowDimensions();
  function getLinkElements() {
    const { userLinks } = config;

    return userLinks.map((link) => (
      <a href={link.url} key={link.label}>
        <S.FooterButton type="button">
          {link.iconClassName === "fa fa-facebook" ? (
            <FaFacebook
              size={windowWidth > DIMENSIONS.breakpoints.mobile ? 31 : 22}
              style={{
                marginBottom:
                  windowWidth < DIMENSIONS.breakpoints.mobile && "-8px",
              }}
            />
          ) : (
            <FaEnvelope
              size={windowWidth > DIMENSIONS.breakpoints.mobile ? 31 : 22}
              style={{
                marginBottom:
                  windowWidth < DIMENSIONS.breakpoints.mobile && "-8px",
                marginRight: windowWidth < DIMENSIONS.breakpoints.mobile && 0,
              }}
            />
          )}
        </S.FooterButton>
      </a>
    ));
  }
  const { userLinks } = config;
  if (!userLinks) {
    return null;
  }
  return (
    <div
      className="user-links"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent:"flex-end",
        paddingRight: windowWidth < DIMENSIONS.breakpoints.mobile ? "12px" :  '30px'
      }}
    >
      {getLinkElements()}
    </div>
  );
}

export default UserLinks;
